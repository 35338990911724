<template>
  <GradientBackLayout class="ModeratorAdmin" title="moderators_admin_title" shadowText="moderators_admin_shadowtitle">
    <BoxShadow class="ModeratorAdminShadow">
      <div class="ModeratorAdmin__Info">
        <div class="ModeratorAdmin__Info__Title">
          <div>{{$t('moderators_admin_pagetitle')}}</div>
        </div>
        <div class="ModeratorAdmin__Info__Text">
          <div>{{$t('moderators_admin_text')}}
          </div>
        </div>
      </div>
      <Separator/>
      <div class="ModeratorAdmin__Add">
        <CommunityButton color="var(--accent-color)" type="EL" v-on:click="addUser()">{{$t('moderators_admin_adduser')}}</CommunityButton>
      </div>
      <div class="ModeratorAdmin__Users">
        <div class="ModeratorAdmin__UserList">
          <BoxShadow styleClasses="ModeratorAdmin__User" v-for="moderator in communityModerators" v-on:click.native="editUser(moderator.id)" :class="{'active':userForm.id == moderator.id}">
            <div class="ModeratorAdmin__User__Container">
              <div class="ModeratorAdmin__User__Card">
                <div class="ModeratorAdmin__User__Icon">
                  <ModeratorAdminSVG :name="moderator.role+'_BIG'"/>
                </div>
                <div class="ModeratorAdmin__User__Data">
                  <div class="ModeratorAdmin__User__Name">
                    {{moderator.name}} {{moderator.surname}}
                  </div>
                  <div class="ModeratorAdmin__User__Role">
                    <div v-if="moderator.role == 'ADMINISTRATOR'">{{$t('moderators_admin_administrator')}}</div>
                    <div v-if="moderator.role == 'MODERATOR'">{{$t('moderators_admin_moderator')}}</div>
                    <div v-if="moderator.role == 'OBSERVER'">{{$t('moderators_admin_observer')}}</div>
                  </div>
                </div>
              </div>
              <div class="ModeratorAdmin__User__Actions">
                <div v-on:click="deleteUser(moderator.id)">
                <ModeratorAdminSVG :name="'DELETE'" />
                </div>
                <ModeratorAdminSVG :name="'EDIT'"/>
              </div>
            </div>
          </BoxShadow>
        </div>
        <div class="ModeratorAdmin__EditUser" v-show="editingUser">
          <div class="ModeratorAdmin__EditUser__Title">
            <span v-show="newUser">{{$t('modators_admin_newusertitle')}}</span>
            <span v-show="!newUser">{{$t('modators_admin_editusertitle')}}</span>
          </div>
          <Separator></Separator>
          <div class="ModeratorAdmin__EditUser__Data">
            <CommunityTextInput ref="name" id="name" :placeholder="$t('name')" v-model="userForm.name" :required="true"/>
            <CommunityTextInput ref="surname" id="surname" :placeholder="$t('surname')" v-model="userForm.surname" :required="true"/>
            <CommunityTextInput :disabled="!newUser" ref="email" id="email" :placeholder="$t('profile_personalization_email')" v-model="userForm.email" :required="true" :email="true"/>
            <CommunityTextInput ref="password" v-if="newUser" id="password" :placeholder="$t('password')" v-model="userForm.password" :type="'password'" :required="true" :minLength="6"/>
          </div>
          <div class="ModeratorAdmin__EditUser__UserTypes">
              <BoxShadow :styleClasses="'ModeratorAdmin__EditUser__UserType'" :class="{'active':userForm.role == 'ADMINISTRATOR'}" v-on:click.native="changeUserType('ADMINISTRATOR')">
                <ModeratorAdminSVG :name="'ADMINISTRATOR'"/><span>{{$t('moderators_admin_administrator')}}</span></BoxShadow>
              <BoxShadow :styleClasses="'ModeratorAdmin__EditUser__UserType'" :class="{'active':userForm.role == 'MODERATOR'}" v-on:click.native="changeUserType('MODERATOR')">
                <ModeratorAdminSVG :name="'MODERATOR'"/><span>{{$t('moderators_admin_moderator')}}</span></BoxShadow>
              <BoxShadow :styleClasses="'ModeratorAdmin__EditUser__UserType'" :class="{'active':userForm.role == 'OBSERVER'}" v-on:click.native="changeUserType('OBSERVER')">
                <ModeratorAdminSVG :name="'OBSERVER'"/><span>{{$t('moderators_admin_observer')}}</span></BoxShadow>
          </div>
          <div class="ModeratorAdmin__Activities">
            <div class="ModeratorAdmin__Activities__Title">{{$t('modators_admin_selectvisibility')}}</div>
            <div class="ModeratorAdmin__Activities__Visibility">
              <BoxShadow :styleClasses="'ModeratorAdmin__Activities__Visibility__Box'" :class="{'active':userForm.allActivities}" v-on:click.native="changeAllActivities(true)">
                <div class="ModeratorAdmin__Activities__Visibility__Title">
                  {{$t('modators_admin_visibAllActivities')}}
                </div>
                <div>
                  {{$t('modators_admin_visibAllActivities_text')}}
                </div>
              </BoxShadow>
              <BoxShadow :styleClasses="'ModeratorAdmin__Activities__Visibility__Box'" :class="{'active':!userForm.allActivities}" v-on:click.native="changeAllActivities(false)">
                <div class="ModeratorAdmin__Activities__Visibility__Title">
                  {{$t('modators_admin_visibSomeActivities')}}
                </div>
                <div>
                  {{$t('modators_admin_visibSomeActivities_text')}}
                </div>
              </BoxShadow>
            </div>
            <div class="ModeratorAdmin__ActivityList" :key="activitiesRefresh">
              <ActivitySelectionBox :disabled="userForm.allActivities" :activity="activity" v-for="activity in communityActivities" :active="userForm.activitiesAllowed.includes(activity.identifier)" v-on:checked="checkedActivity(activity.identifier)" v-on:unchecked="uncheckedActivity(activity.identifier)"/>
            </div>
          </div>
          <Separator></Separator>
          <div class="ModeratorAdmin__Error" v-show="errorMessage">
            {{$t('modators_admin_error')}}
          </div>
          <div class="ModeratorAdmin__Add">
            <CommunityButton color="grey" type="EL" v-on:click="cancelEdit()">{{$t('action_cancel')}}</CommunityButton>
            <CommunityButton color="var(--accent-color)" type="EL" v-on:click="saveUser()">{{$t('action_save')}}</CommunityButton>
          </div>
        </div>
      </div>
    </BoxShadow>
  </GradientBackLayout>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters, mapMutations  } from 'vuex';
import GradientBackLayout from '@/layouts/GradientBackLayout.vue';
import CommunityButton from '@/components/CommunityButton.vue';
import ActivitySelectionBox from '@/components/ActivitySelectionBox.vue';
import Separator from '@/components/Separator.vue';
import BoxShadow from '@/components/BoxShadow.vue';
import CommunityTextInput from '@/components/CommunityTextInput.vue';
import ModeratorAdminSVG from '@/components/ModeratorAdmin/ModeratorAdminSVG.vue';
import store from '@/store';
import {
  COMMUNITY_FETCH_ACTIVITIES
} from '@/store/community/activities.module';
import {
  COMMUNITY_FETCH_MODERATORS,
  COMMUNITY_FETCH_MODERATOR,
  COMMUNITY_ADD_MODERATOR,
  COMMUNITY_UPDATE_MODERATOR,
  COMMUNITY_DELETE_MODERATOR
}  from '@/store/community/moderators.module';
import { debounce }             from '@/utils';


export default {
  name: 'ModeratorAdminPage',
  data() {
    return {
      editingUser: false,
      newUser: false,
      errorMessage: false,
      activitiesRefresh: 0,
      userForm: {
        id:0,
        name:"",
        surname:"",
        email:"",
        password:"",
        role:"",
        allActivities: true,
        activitiesAllowed: []
      },
      errors:[]
    }
  },
  computed: {
    ...mapGetters([
      'communityActivities',
      'communityModerators',
      'communityCurrentModerator'
    ]),
  },

  components: {
    GradientBackLayout,
    Separator,
    CommunityButton,
    BoxShadow,
    CommunityTextInput,
    ModeratorAdminSVG,
    ActivitySelectionBox
  },
  methods:{
    addUser(){
      this.editingUser = true;
      this.newUser = true;
      this.userForm= {
        id: 0,
        name:"",
        surname:"",
        email:"",
        password:"",
        role:"MODERATOR",
        allActivities: true,
        activitiesAllowed: []
      }
    },
    editUser(id){
      this.editingUser = true;
      this.newUser = false;
      store.dispatch(COMMUNITY_FETCH_MODERATOR, {moderatorId: id}).then((data)=>{
          this.userForm.id = data.id;
          this.userForm.name = data.name;
          this.userForm.surname = data.surname;
          this.userForm.email = data.email;
          this.userForm.role = data.role;
          this.userForm.allActivities = data.allActivities;
          if (data.activitiesAllowed && data.activitiesAllowed.length>0){
            this.userForm.activitiesAllowed = data.activitiesAllowed;
          } else {
            this.userForm.activitiesAllowed = [];
          }
          this.activitiesRefresh++;
      });
    },
    saveUser(){
      let vm = this
      this.errors = []
      this.errorMessage = false;
        if (this.newUser){
          let error ="";
          error = this.$refs.name.validate();
          if (error) this.errors.push(error);
          error = this.$refs.surname.validate();
          if (error) this.errors.push(error);
          error = this.$refs.email.validate();
          if (error) this.errors.push(error);
          error = this.$refs.password.validate();
          if (error) this.errors.push(error);
          if(this.errors.length == 0){
            store.dispatch(COMMUNITY_ADD_MODERATOR, {data:this.userForm}).then((response)=>{
                if (response.status == 400){
                  if (response.data.payload.includes("email")){
                    Swal.fire({
                      title:this.$t('modators_admin_error_title'),
                      text:this.$t('modators_admin_error_email'),
                      icon:"error",
                      customClass:{popup:"swal-customError"},
                    })
                  } else{
                    Swal.fire({
                      title:this.$t('modators_admin_error_title'),
                      text:this.$t('modators_admin_error_saving'),
                      icon:"error",
                      customClass:{popup:"swal-customError"},
                    })
                  }
                } else if(response.newClient) {
                  Swal.fire({
                    title: vm.$t('modators_admin_success_title'),
                    text: vm.$t('modators_admin_success_text'),
                    icon: 'success',
                    confirmButtonText: vm.$t('action_accept'),
                    customClass:{
                      popup:'swal-customSuccess'
                    }
                  }).then((result) => {
                    if (result.isConfirmed) {
                      store.dispatch(COMMUNITY_FETCH_MODERATORS);
                      vm.userForm= {
                        id: 0,
                        name:"",
                        surname:"",
                        email:"",
                        password:"",
                        role:"MODERATOR",
                        allActivities: true,
                        activitiesAllowed: []
                      }
                    }
                  })
                }
                else{
                  Swal.fire({
                    title: vm.$t('modators_admin_success_title'),
                    text: vm.$t('modators_admin_success_text_2',{email:response.email}),
                    icon: 'success',
                    confirmButtonText: vm.$t('action_accept'),
                    customClass:{
                      popup:'swal-customSuccess'
                    }
                  }).then((result) => {
                    if (result.isConfirmed) {
                      store.dispatch(COMMUNITY_FETCH_MODERATORS);
                      vm.userForm= {
                        id: 0,
                        name:"",
                        surname:"",
                        email:"",
                        password:"",
                        role:"MODERATOR",
                        allActivities: true,
                        activitiesAllowed: []
                      }
                    }
                  })
                }
            });
          } else {
            this.errorMessage = true;
          }
        }else{
          let error ="";
          error = this.$refs.name.validate();
          if (error) this.errors.push(error);
          error = this.$refs.surname.validate();
          if (error) this.errors.push(error);
          if(this.errors.length == 0){
            store.dispatch(COMMUNITY_UPDATE_MODERATOR, {id:this.userForm.id,data:this.userForm}).then((response)=>{
              if (response.status == 400){
                if (response.data.payload.includes("email")){
                  Swal.fire({
                    title:this.$t('modators_admin_error_title'),
                    text:this.$t('modators_admin_error_email'),
                    icon:"error",
                    customClass:{popup:"swal-customError"},
                  })
                } else{
                  Swal.fire({
                    title:this.$t('modators_admin_error_title'),
                    text:this.$t('modators_admin_error_saving'),
                    icon:"error",
                    customClass:{popup:"swal-customError"},
                  })
                }
              } else {
                Swal.fire({
                  title: this.$t('modators_admin_success_title'),
                  text: this.$t('modators_admin_success_text'),
                  icon: 'success',
                  confirmButtonText: this.$t('action_accept'),
                  customClass:{
                    popup:'swal-customSuccess'
                  }
                }).then((result) => {
                  if (result.isConfirmed) {
                    store.dispatch(COMMUNITY_FETCH_MODERATORS);
                  }
                })
              }
            });
          } else {
            this.errorMessage = true;
          }
        }
    },
    deleteUser(id){
      let vm = this
      Swal.fire({
        title: this.$t('modators_admin_delete_confirmtitle'),
        text: this.$t('modators_admin_delete_confirmtext'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_delete'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(COMMUNITY_DELETE_MODERATOR, {moderatorId:vm.userForm.id}).then((data)=>{
              vm.editingUser = false;
              if (data.data && data.data.error) {
                Swal.fire({
                    title: data.data.payload == "Can't delete main admin" ? 'No se puede eliminar este usuario' : 'Ha ocurrido un error al intentar eliminar el usuario',
                    text: data.data.payload == "Can't delete main admin" ? 'No se puede eliminar el usuario porque es un main admin' : 'Póngase en contacto con nosotros',
                    icon: 'error'
                })
                return
              }
              Swal.fire({
                title: vm.$t('modators_admin_success_title'),
                text: vm.$t('modators_admin_delete_text'),
                icon: 'success',
                confirmButtonText: this.$t('action_accept'),
                customClass:{
                  popup:'swal-customSuccess'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  console.log('COMMUNITY_FETCH_MODERATORS')
                  store.dispatch(COMMUNITY_FETCH_MODERATORS);
                }
              })
          });
        }
      })
    },
    cancelEdit(){
      this.editingUser = false;
    },
    changeUserType(type){
      this.userForm.role = type;
    },
    fetchActivities: async function() {
      let priority = "myActivities"
      return await store.dispatch(COMMUNITY_FETCH_ACTIVITIES, {priority});
    },
    fetchModerators: async function() {
      return await store.dispatch(COMMUNITY_FETCH_MODERATORS);
    },
    checkedActivity(activityId){
      this.userForm.activitiesAllowed.push(activityId);
      console.log(this.userForm.activitiesAllowed);
    },
    uncheckedActivity(activityId){
      if (this.userForm.activitiesAllowed.indexOf(activityId) !== -1){
          this.userForm.activitiesAllowed.splice(this.userForm.activitiesAllowed.indexOf(activityId),1);
      }
      console.log(this.userForm.activitiesAllowed);
    },
    changeAllActivities(allActivities){
      this.userForm.allActivities = allActivities;
      this.activitiesRefresh++;
    }
  },
  async mounted(){
    this.fetchModerators()
    this.fetchActivities()
  }
}
</script>
<style scoped lang="scss">
.active{
    .svg{
      filter: brightness(0) invert(1);
    }
}
.ModeratorAdminShadow{
  flex-direction: column;
  padding: 50px;
  @media only screen and (max-width: 600px) {
    padding: 25px 10px;
  }
}
.ModeratorAdmin{
  &__Info{
    display: flex;
    width: 100%;
    &__Title{
      width:35%;
      font-size: 35px;
      font-weight: bold;
      @media only screen and (max-width: 600px) {
      font-size: 30px;
  }
    }
    &__Text{
      width:65%;
    }
  }
  &__Add{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button{
      margin-left: 10px;
    }
  }
  &__Users{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  &__UserList{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-width: 50%;
    flex-grow: 1;
    align-content: flex-start;
  }
  &__User{
    //height: 100px;
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    order: 1;
    flex-grow: 1;
    width: 46%;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      margin-right: 10px;
    
  }
    &__Container{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &__Card{
      display: flex;
    }
    &__Icon{
    }
    &__Data{
        margin-left:20px;
    }
    &__Name{
      font-size: 20px;
      font-weight: bold;
    }
    &__Actions{
      display: flex;
      align-items: center;
      div{
        margin-left: 10px;
      }
    }
  }
  &__EditUser{
    min-width: 48%;
    order: 2;
    //align-self: flex-end;
    flex-grow: 1;
    box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
    padding: 20px;
    &__Title{
      font-size: 30px;
      font-weight: bold;
    }
    &__Data{
      display: flex;
      flex-wrap: wrap;
    }
    &__UserTypes{
      display: flex;
      width: 100%;
      margin-top: 10px;
      @media only screen and (max-width: 600px) {
        flex-direction: column;
        flex-grow: 1;
  }
    }
    &__UserType{
      width: 32%;
      margin: auto;
      cursor: pointer;
      
      span{
        margin-top:7px;
        margin-left: 10px;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
  }
    }
  }
  &__Activities{
    &__Title{
      font-size: 20px;
      font-weight: bold;
      margin-top:30px;
      margin-bottom:10px;
    }
    &__Visibility{
      display: flex;
      font-size: 14px;
      &__Box{
        width: 49%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
      }
      &__Title{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }
  &__ActivityList{
    margin-top: 20px;
  }
  &__Error{
    background-color: #d9534f;
    color: white;
    padding: 3px;
    font-size: 0.9em;
    margin-bottom: 10px;
  }
}
@media (max-width: 900px){
  .ModeratorAdmin{
    &__Info{
      display: block;
      &__Title{
        width: 100%;
      }
      &__Text{
        width: 100%;
      }
    }
    &__Users{
      display: block;
    }
  }
}
</style>
